import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaFilePdf } from 'react-icons/fa';
import Loader from './loader';

const Container = styled.div`
    /* background: #999; */
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 10px;
    overflow-x: scroll;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
    display: grid;
    /* border-collapse: collapse; */
    min-width: 100%;
    grid-template-columns:
        minmax(200px, 2fr)
        minmax(100px, 1fr)
        minmax(150px, 1.5fr)
        /* minmax(150px, 1.5fr) */
        minmax(80px, 1fr)
        minmax(80px, 1fr)
        minmax(150px, 1.5fr);
`;
const StyledThead = styled.thead`
    display: contents;
`;
const StyledTh = styled.th`
    padding: 15px;
    /* word-wrap: break-word; */
    overflow: auto;
    text-overflow: auto;
    /* white-space: nowrap; */

    position: sticky;
    top: 0;
    background: green;
    text-align: center;
    font-weight: normal;
    font-size: 1.1rem;
    color: white;
    border-right: 1px solid #fff;
    &:last-child {
        border-right: none;
    }
`;
const StyledTbody = styled.tbody`
    display: contents;
`;

const StyledTr = styled.tr`
    /* border: 1px solid #333; */
    text-align: center;
    display: contents;

    &:nth-child(even) td {
        background: #f8f6ff;
    }
`;
const StyledTd = styled.td`
    border-right: 2px solid #999;
    /* border-left: 1px solid #111; */
    text-align: center;
    border-bottom: 1px solid #999;
    padding: 15px;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */

    padding-top: 10px;
    padding-bottom: 10px;
    color: #333;
    &:first-child {
        grid-column-start: span 7;
        border-left: 2px solid #999;
    }
    &:nth-child(2) {
        border-left: 2px solid #999;
    }
`;

const KidsEduTable = () => {
    const [pods, setPods] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getPods = async () => {
            setLoading(true);
            const response = await axios.get(
                'https://wp-api.proff86.ru/wp-json/wp/v2/kids_edu_table?per_page=100'
            );
            // eslint-disable-next-line no-console
            // console.log("@@@", response.data);
            setPods(response.data);
            setLoading(false);
        };
        getPods();
    }, []);

    const renderedPods = pods.map((pod) => {
        return (
            <StyledTr key={pod.ID}>
                <StyledTd className="text-lg font-bold tracking-wider">
                    {pod.title.rendered}
                </StyledTd>
                <StyledTd>Очно</StyledTd>
                <StyledTd>
                    <ReactTooltip id={pod.plan.ID} />
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        key={pod.plan.ID}
                        data-tooltip-id={pod.plan.ID}
                        href={pod.plan.guid}
                        data-tooltip-content={pod.plan.post_title}
                    >
                        <FaFilePdf
                            color="red"
                            size="1.4em"
                            style={{ display: 'inline', marginRight: '0.5rem' }}
                        />
                    </a>
                </StyledTd>
                <StyledTd>
                    <ReactTooltip id={pod.grafik.ID} />
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        key={pod.grafik.ID}
                        data-tooltip-id={pod.grafik.ID}
                        href={pod.grafik.guid}
                        data-tooltip-content={pod.grafik.post_title}
                    >
                        <FaFilePdf
                            color="red"
                            size="1.4em"
                            style={{ display: 'inline', marginRight: '0.5rem' }}
                        />
                    </a>
                </StyledTd>
                <StyledTd>{pod.hours}</StyledTd>
                <StyledTd>русский</StyledTd>
                <StyledTd>
                    {pod.materials &&
                        pod.materials.map((file) => (
                            <React.Fragment
                                key={`${file.ID} - ${file.post_title}`}
                            >
                                <ReactTooltip id={file.ID} />
                                <p
                                    data-tooltip-id={file.ID}
                                    data-tooltip-content={file.post_title}
                                    className="inline-block"
                                >
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={file.guid}
                                        className="hover:text-green-700"
                                    >
                                        <FaFilePdf
                                            color="red"
                                            size="1.4em"
                                            style={{
                                                display: 'inline',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                    </a>
                                </p>
                            </React.Fragment>
                        ))}
                </StyledTd>
            </StyledTr>
        );
    });

    return (
        <Container>
            <StyledTable>
                <StyledThead>
                    <StyledTr>
                        <StyledTh>Форма обучения</StyledTh>
                        <StyledTh>Учебный план</StyledTh>
                        <StyledTh>Календарный учебный график</StyledTh>
                        <StyledTh>Объем часов</StyledTh>
                        <StyledTh>
                            Язык
                            <wbr />
                            образования
                            <wbr />
                            (обучения)
                        </StyledTh>
                        <StyledTh>
                            Методи
                            <wbr />
                            ческие материалы и иные документы
                        </StyledTh>
                    </StyledTr>
                </StyledThead>
                {!loading && <StyledTbody>{renderedPods}</StyledTbody>}
                {loading && <Loader />}
            </StyledTable>
        </Container>
    );
};

export default KidsEduTable;
