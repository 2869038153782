/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import Layout from '../components/layout';
import Docs from '../components/docs';
import Modal from '../components/modal';

import SEO from '../components/seo';

import EduTable from '../components/eduTable';
import ProgrammsTable from '../components/programsTable';
import Licenze from '../../static/docs/License.pdf';
// import EduTableFromJson from '../components/eduTableFromJson';
// import { graphql, useStaticQuery } from 'gatsby';
import DopEduTable from '../components/dopEduTableApi';
import NumberStudentsTable from '../components/numberStudents';
import KidsEduTable from '../components/kidsEduTable';

function Education() {
    // const { allFile } = useStaticQuery(graphql`
    //   query {
    //     allFile(filter: { sourceInstanceName: { eq: "cugs" } }) {
    //       edges {
    //         node {
    //           name
    //           publicURL
    //           extension
    //           id
    //         }
    //       }
    //     }
    //   }
    // `);
    const mainURL =
        'https://wp-api.proff86.ru/wp-json/wp/v2/edu_programs/?per_page=99';

    // const { edges } = allFile;

    const [modalOpened, setModalOpened] = useState(false);
    const [zModalOpened, setZmodalOpened] = useState(false);
    const [pModalOpened, setPmodalOpened] = useState(false);
    const [kidsModalOpened, setKidsModalOpened] = useState(false);

    const handleModalClose = () => {
        setModalOpened(false);
        setZmodalOpened(false);
        setPmodalOpened(false);
        setKidsModalOpened(false);
    };

    return (
        <Layout>
            <SEO
                keywords={[
                    `Образование`,
                    `Профф`,
                    `Пыть-Ях`,
                    `Профессиональное образование`,
                ]}
                title="Образование"
            />

            <section className="flex flex-col items-center ">
                <div className="w-full bg-green-200 p-2 my-6 rounded shadow-lg cursor-pointer">
                    <a href={Licenze} target="_blank" rel="noopener noreferrer">
                        <h2 className="text-green-700 text-2xl text-center">
                            Лицензия на осуществление образовательной
                            деятельности.pdf
                        </h2>
                    </a>
                </div>
                <h1 className="text-4xl  text-center uppercase tracking-wider text-green-700">
                    ИНФОРМАЦИЯ О РЕАЛИЗУЕМЫХ ПРОГРАММАХ
                </h1>
                <ProgrammsTable />
                <h1 className="text-4xl mt-4 text-center uppercase tracking-wider text-green-700">
                    ЧИСЛЕННОСТЬ ОБУЧАЮЩИХСЯ
                </h1>
                <NumberStudentsTable />

                {/* <h5 className='text-4xl  text-center uppercase tracking-wider text-green-700'>
          УЧЕБНЫЕ ГРАФИКИ
        </h5>
        <div>
          {edges.map(({ node: { id, name, extension, publicURL } }) => {
            return (
              <div
                key={id}
                className='text-blue-600 hover:text-blue-400 cursor-pointer'
              >
                <a
                  href={publicURL}
                  target='blank'
                  rel='noopener noreferer'
                >{`${name}.${extension}`}</a>
              </div>
            );
          })}
        </div> */}

                {/* <section className='flex flex-col items-center '> */}
                {/* <h1 className='text-4xl uppercase tracking-wider '>
          Образовательные программы
        </h1> */}
                <div
                    className="py-4 px-8 m-4 rounded bg-white hover:bg-gray-300  cursor-pointer"
                    onClick={() => setPmodalOpened(true)}
                >
                    <h1 className="text-3xl text-center uppercase tracking-wider text-green-700 hover:text-gray-700">
                        Образовательные программы
                    </h1>
                </div>

                {/* </section> */}
                <div
                    className="py-4 px-8 m-4 rounded bg-white hover:bg-gray-300  cursor-pointer"
                    onClick={() => setModalOpened(true)}
                >
                    <h1 className="text-3xl text-center uppercase tracking-wider text-green-700 hover:text-gray-700">
                        ПРОФЕССИОНАЛЬНОЕ ОБУЧЕНИЕ
                    </h1>
                </div>

                <div
                    className="py-4 px-8 rounded bg-white hover:bg-gray-300 cursor-pointer hover:text-gray-700"
                    onClick={() => setZmodalOpened(true)}
                >
                    <h1
                        className="text-3xl text-center uppercase tracking-wider text-green-700"
                        onClick={() => setZmodalOpened(true)}
                    >
                        Дополнительное профессиональное образование
                    </h1>
                </div>
                <div
                    className="mt-4 py-4 px-8 rounded bg-white hover:bg-gray-300 cursor-pointer hover:text-gray-700"
                    onClick={() => setKidsModalOpened(true)}
                >
                    <h1
                        className="text-3xl text-center uppercase tracking-wider text-green-700"
                        onClick={() => setKidsModalOpened(true)}
                    >
                        Дополнительное образование детей и взрослых
                    </h1>
                </div>
            </section>
            {modalOpened && (
                <Modal onClose={handleModalClose}>
                    {/* <EduTableFromJson /> */}
                    <h1 className="text-3xl  text-center uppercase tracking-wider text-green-700">
                        ПРОФЕССИОНАЛЬНОЕ ОБУЧЕНИЕ
                    </h1>
                    <EduTable />
                </Modal>
            )}
            {zModalOpened && (
                <Modal onClose={handleModalClose}>
                    <h1 className="text-3xl  text-center uppercase tracking-wider text-green-700">
                        ДОПОЛНИТЕЛЬНОЕ ПРОФЕССИОНАЛЬНОЕ ОБРАЗОВАНИЕ
                    </h1>
                    <DopEduTable />
                </Modal>
            )}
            {kidsModalOpened && (
                <Modal onClose={handleModalClose}>
                    <h1 className="text-3xl  text-center uppercase tracking-wider text-green-700">
                        ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ ДЕТЕЙ И ВЗРОСЛЫХ
                    </h1>
                    <KidsEduTable />
                </Modal>
            )}
            {pModalOpened && (
                <Modal onClose={handleModalClose}>
                    <Docs url={mainURL} title="Образовательные программы" />
                </Modal>
            )}
        </Layout>
    );
}

export default Education;
