import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import Modal from './modal';

import { FaFilePdf } from 'react-icons/fa';
// import ReactPdf from './ReactPdf';

const Container = styled.div`
  background: white;
  width: 100%;
  margin-top: 2rem;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 2rem;
  overflow-x: scroll;
`;

const Docs = ({ url, title }) => {
  const [docs, setDocs] = useState([]);
  // const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    const getPods = async () => {
      const response = await axios.get(url);
      setDocs(response.data);
    };
    getPods();
  }, []);

  // const handleModalClose = () => {
  //   setModalOpened(false);
  // };

  return (
    <Container>
      {/* <pre>{JSON.stringify(docs, null, 4)}</pre> */}
      <h2 className='mb-4 text-2xl font-bold text-center'>{title}</h2>
      {docs
        .sort((a, b) => a.file.post_title?.localeCompare(b.file.post_title))
        .map(({ id, file }) => {
          //console.log(file);
          return (
            <React.Fragment key={id}>
              {/* {modalOpened && (
                <Modal onClose={handleModalClose}>
                  <ReactPdf url={file.guid} />
                </Modal>
              )} */}
              <a href={file.guid} target='blank' className='flex'>
                {/* <button onClick={() => setModalOpened(true)} className='flex'> */}
                <FaFilePdf
                  color='red'
                  size='1.4em'
                  style={{ display: 'inline', marginRight: '0.5rem' }}
                />
                <h6 className='mb-2 text-blue-600 hover:text-blue-800 flex flex-col justify:center'>
                  {file.post_title}
                </h6>
                {/* </button> */}
              </a>
            </React.Fragment>
          );
        })}
    </Container>
  );
};

Docs.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Docs;
