import React from "react";
import styled from "styled-components";

const Container = styled.div`
  /* background: #999; */
  overflow-x: scroll;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
  display: grid;
  /* border-collapse: collapse; */
  min-width: 100%;
  grid-template-columns:
    minmax(300px, 4fr)
    minmax(80px, 1fr);
`;
/* const StyledThead = styled.thead` 
  display: contents;
`;
const StyledTh = styled.th`
  border-right: 1px solid #fff;
  padding: 15px;
  overflow: hidden;
  text-overflow: hidden ;
  top: 0;
  background: green;
  text-align: center;
  font-weight: normal;
  font-size: 1.1rem;
  color: white;
  &:last-child {
    border-right: none;
  }
`; */
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  /* border: 1px solid #333; */
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 2px solid #999;
  /* border-left: 1px solid #111; */
  text-align: center;
  border-bottom: 1px solid #999;
  border-top: 1px solid #999;
  padding: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  &:first-child {
    /* grid-column-start: span 7; */
    border-left: 2px solid #999;
    border-right: 0;
  }
  &:nth-child(2) {
    border-left: 2px solid #999;
  }
`;

const tableData = [
  {
    info: "Общая численность обучающихся",
    number: 3342,
  },
  {
    info:
      "Численность обучающихся за счет бюджетных ассигнований федерального бюджета,",
    number: 0,
  },
  {
    info:
      "- в том числе численность обучающихся, являющихся иностранными гражданами",
    number: 0,
  },
  {
    info:
      "Численность обучающихся за счет бюджетных ассигнований бюджетов субъектов Российской Федерации,",
    number: 0,
  },
  {
    info:
      "- в том числе численность обучающихся, являющихся иностранными гражданами",
    number: 0,
  },
  {
    info:
      "Численность обучающихся за счет бюджетных ассигнований местных бюджетов,",
    number: 0,
  },
  {
    info:
      "- в том числе численность обучающихся, являющихся иностранными гражданами",
    number: 0,
  },
  {
    info:
      "Численность обучающихся по договорам об образовании, заключаемых при приеме на обучение за счет средств физического и (или) юридического лица",
    number: 3342,
  },
  {
    info:
      "- в том числе численность обучающихся, являющихся иностранными гражданами",
    number: 0,
  },
];

const NumberStudentsTable = () => {
  return (
    <Container>
      <StyledTable>
        {/* <StyledThead>
          <StyledTr>
            <StyledTh>Общая численность обучающихся</StyledTh>
            <StyledTh>Форма обучения</StyledTh>
            <StyledTh>Общий объем часов</StyledTh>
            <StyledTh>Язык обучения</StyledTh>
          </StyledTr>
        </StyledThead> */}
        <StyledTbody>
          {tableData.map((row, idx) => (
            <StyledTr key={idx}>
              <StyledTd className="text-lg font-bold tracking-wider">
                {row.info}
              </StyledTd>
              <StyledTd>{row.number} чел.</StyledTd>
            </StyledTr>
          ))}
        </StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default NumberStudentsTable;
