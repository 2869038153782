import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  /* background: #999; */
  overflow-x: scroll;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
  display: grid;
  /* border-collapse: collapse; */
  min-width: 100%;
  grid-template-columns:
    minmax(190px, 1.5fr)
    minmax(100px, 1fr)
    minmax(300px, 3fr)
    minmax(100px, 1fr);
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  border-right: 1px solid #fff;
  padding: 15px;
  /* word-wrap: break-word; */
  overflow: hidden;
  text-overflow: hidden;
  /* white-space: nowrap; */

  /* position: sticky; */
  top: 0;
  background: green;
  text-align: center;
  font-weight: normal;
  font-size: 1.1rem;
  color: white;
  &:last-child {
    border-right: none;
    /* background-color: red; */
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  /* border: 1px solid #333; */
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 2px solid #999;
  /* border-left: 1px solid #111; */
  text-align: center;
  border-bottom: 1px solid #999;
  padding: 15px;
  overflow: hidden;
  display: flex;
  align-items:center;
  justify-content: center;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  &:first-child {
    /* grid-column-start: span 7; */
    border-left: 2px solid #999;
    border-right:0;
  }
  &:nth-child(2) {
    border-left: 2px solid #999;
  } 
`;
// const StyledFirstTd = styled(StyledTd)`
//   /* border-left: 1px solid #999; */
// `;

// th:last-child {
//   border: 0;
// }

const ProgramsTable = () => {
  // const renderedPods = () => {
  //   return (
  //     <>
  //       <StyledTr>
  //         <StyledTd className='text-lg font-bold tracking-wider'>
  //           Профессиональное обучение
  //         </StyledTd>
  //         <StyledTd>Очно-заочная</StyledTd>
  //         <StyledTd>
  //           профессиональная подготовка - от 180 часов (от 25 учебных дней;
  //           заочная форма); повышение квалификации - от 16 часов (от 2 учебных
  //           дней, заочная форма); дистанционные технологии; договорные цены
  //         </StyledTd>
  //         <StyledTd>Русский</StyledTd>
  //       </StyledTr>

  //       <StyledTr>
  //         <StyledTd className='text-lg font-bold tracking-wider'>
  //           Дополнительное профессиональное образование
  //         </StyledTd>
  //         <StyledTd>Очно-заочная</StyledTd>
  //         <StyledTd>
  //           профессиональная переподготовка - от 250 часов (от 32 учебных дней;
  //           заочная форма); повышение квалификации - от 16 часов (от 2 учебных
  //           дня; очно-заочная и заочная формы); дистанционные технологии;
  //           договорные цены
  //         </StyledTd>
  //         <StyledTd>Русский</StyledTd>
  //       </StyledTr>
  //     </>
  //   );
  // };

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>Реализуемая программа</StyledTh>
            <StyledTh>Форма обучения</StyledTh>
            <StyledTh>Общий объем часов</StyledTh>
            <StyledTh>Язык обучения</StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>
          {/* {renderedPods} */}
          <StyledTr>
            <StyledTd className='text-lg font-bold tracking-wider'>
              Профессиональное обучение
            </StyledTd>
            <StyledTd>Очно-заочная</StyledTd>
            <StyledTd>
              профессиональная подготовка - от 180 часов (от 25 учебных дней;
              заочная форма); повышение квалификации - от 16 часов (от 2 учебных
              дней, заочная форма); дистанционные технологии; договорные цены
            </StyledTd>
            <StyledTd>Русский</StyledTd>
          </StyledTr>

          <StyledTr>
            <StyledTd className='text-lg font-bold tracking-wider'>
              Дополнительное профессиональное образование
            </StyledTd>
            <StyledTd>Очно-заочная</StyledTd>
            <StyledTd>
              профессиональная переподготовка - от 250 часов (от 32 учебных
              дней; заочная форма); повышение квалификации - от 16 часов (от 2
              учебных дня; очно-заочная и заочная формы); дистанционные
              технологии; договорные цены
            </StyledTd>
            <StyledTd>Русский</StyledTd>
          </StyledTr>
        </StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default ProgramsTable;
